import React, { createContext, useReducer, useEffect, useState } from "react";
import { reducer, initialState } from "./reducer";
import { useAuthenticator } from "@aws-amplify/ui-react"; // Import Amplify's Authenticator
import { fetchAuthSession } from "aws-amplify/auth";

export const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const { user, signOut } = useAuthenticator();

  const onSuccessfulModification = () => {
    setRefreshTrigger((prev) => prev + 1);
  };

  // Function to handle loading states
  const incrementLoading = () => {
    dispatch({ type: "INCREMENT_LOADING" });
  };

  const decrementLoading = () => {
    dispatch({ type: "DECREMENT_LOADING" });
  };

  const makeApiRequest = async (url, options = {}, onSuccess) => {
    if (!user) {
      signOut();
      console.log("User is not authenticated");
      return { success: false, error: "User is not authenticated" };
    }

    try {
      incrementLoading(); // Track the beginning of the request
      const session = await fetchAuthSession();
      const validToken = session.tokens.accessToken;
      if (!validToken) {
        console.log("Invalid or expired token");
        return { success: false, error: "Invalid or expired token" };
      }

      const response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${validToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok)
        throw new Error(`Request failed with status ${response.status}`);

      const data = await response.json();
      if (onSuccess && typeof onSuccess === "function") onSuccess(data);

      return { success: true, data };
    } catch (error) {
      return { success: false, error: error.message };
    } finally {
      decrementLoading(); // Track the completion of the request
    }
  };

  const fetchAllTables = async () => {
    const endpoint = process.env.REACT_APP_DATABASE_URL;
    const options = {
      method: "POST",
      body: JSON.stringify({
        command: "get_all_tables",
        obj: { command: "get_all_tables", item: {} },
      }),
    };
    const { success, data, error } = await makeApiRequest(endpoint, options);
    if (success) {
      dispatch({ type: "SET_DATABASE_DATA", payload: data });
    } else {
      console.error("Failed to fetch data:", error);
    }
  };

  const fetchUserSettings = async () => {
    const endpoint = process.env.REACT_APP_DATABASE_URL;
    // console.log(user.signInDetails.loginId);
    const options = {
      method: "POST",
      body: JSON.stringify({
        command: "command_any_table",
        obj: {
          command: "read",
          item: { uid: user.userId },
          table_name: "user_settings",
        },
      }),
    };
    const { success, data, error } = await makeApiRequest(endpoint, options);
    if (success) {
      dispatch({ type: "SET_SETTINGS", payload: data });
    } else {
      console.error("Failed to fetch user settings:", error);
    }
  };

  const fetchStatus = async () => {
    const endpoint = process.env.REACT_APP_STATUS_URL;
    const options = {
      method: "POST",
      body: JSON.stringify({ command: "get_status" }),
    };
    const { success, data, error } = await makeApiRequest(endpoint, options);
    if (success) {
      dispatch({ type: "SET_STATUS_DATA", payload: data["response"] });
    } else {
      console.error("Failed to fetch status:", error);
    }
  };

  const fetchData = async () => {
    // Dispatch loading state at the start
    incrementLoading();

    await Promise.all([fetchAllTables(), fetchStatus(), fetchUserSettings()]);

    // Dispatch loading state at the end
    decrementLoading();
  };
  useEffect(() => {
    fetchData();

    // // Set up interval to call fetchStatus every x seconds
    // const interval = setInterval(() => {
    //   fetchStatus();
    // }, 200000);

    // Cleanup the interval on component unmount
    // return () => clearInterval(interval);
  }, [refreshTrigger]);

  return (
    <MyContext.Provider value={{ state, dispatch, onSuccessfulModification }}>
      {children}
    </MyContext.Provider>
  );
};
