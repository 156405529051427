import React, { useState, useContext, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import WarningIcon from "@mui/icons-material/Warning";
import MemoryIcon from "@mui/icons-material/Memory";
import JSONDialog from "../Utility/JSONDialog";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import useConfirm from "../Utility/useConfirm";
import { useApiRequest } from "../Utility/useAPIRequest";
import { MyContext } from "../../context/Context";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
// Register required Chart.js elements
ChartJS.register(ArcElement, Tooltip, Legend);

export default function NabletConfig({ configs, nablet }) {
  const { state, onSuccessfulModification } = useContext(MyContext);
  const [openCommandDialog, setOpenCommandDialog] = useState(false);
  const [commandDialogContent, setCommandDialogContent] = useState("");
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const { makeApiRequest } = useApiRequest();

  const openCommand = (command) => {
    setCommandDialogContent(command);
    setOpenCommandDialog(true);
  };

  const requestRestartRecording = async (nablet_process) => {
    try {
      await requestConfirm("Are you sure you want to stop this recording?");
      let url = process.env.REACT_APP_TASKS_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            command: "restart_nablet_recording",
            input: {
              streamid: nablet_process["streamid"],
              recorder_host: nablet["ip"],
            },
          }),
        },
        onSuccessfulModification
      );
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  const requestStopRecording = async (nablet_process) => {
    try {
      await requestConfirm("Are you sure you want to stop this recording?");
      let url = process.env.REACT_APP_TASKS_URL;
      const result = await makeApiRequest(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            command: "stop_nablet_recording",
            input: {
              streamid: nablet_process["streamid"],
              recorder_host: nablet["ip"],
            },
          }),
        },
        onSuccessfulModification
      );
    } catch (error) {
      console.log(error);
      console.log("Update action cancelled by user");
    }
  };

  const closeCommandDialog = () => setOpenCommandDialog(false);

  const createDoughnutData = (usage) => ({
    datasets: [
      {
        data: [usage, 100 - usage],
        backgroundColor: [
          usage <= 50 ? "green" : usage <= 75 ? "orange" : "error.main",
          "#e0e0e0",
        ],
        borderWidth: 1,
      },
    ],
  });

  const getPercentageColor = (percent) => {
    if (percent <= 50) return "green";
    if (percent <= 75) return "orange";
    return "error.main";
  };

  const extractFilename = (cmdline) => {
    const outputFileMatch = cmdline.join(" ").match(/-o\s+(\S+)/);
    return outputFileMatch ? outputFileMatch[1] : "N/A";
  };

  const systemDrive = configs[0].instanceStatus.disks.find(
    (disk) => disk.mountpoint === "/"
  );
  const recordingsDrive = configs[0].instanceStatus.disks.find(
    (disk) => disk.mountpoint === "/mnt/recordings"
  );

  const hasFailedProcesses =
    configs[0].failedProcesses &&
    Object.keys(configs[0].failedProcesses.failed_processes).length > 0;

  const hasRunningProcesses = configs[0].processes.length > 0;

  return (
    <Box>
      {ConfirmDialog}

      {/* Instance Status Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" alignItems="center">
            <MemoryIcon sx={{ marginRight: 1 }} />
            <Typography variant="h6">Instance Status</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box
              display="flex"
              justifyContent="space-evenly"
              gap={5}
              flexWrap="wrap"
            >
              {/* Render each section in a similar style */}
              {[
                {
                  label: "CPU Usage",
                  percent: configs[0].instanceStatus.cpu.percent,
                },
                {
                  label: "Memory Usage",
                  percent: configs[0].instanceStatus.memory.percent,
                  details: {
                    total: configs[0].instanceStatus.memory.total,
                    used: configs[0].instanceStatus.memory.used,
                    free:
                      configs[0].instanceStatus.memory.total -
                      configs[0].instanceStatus.memory.used,
                  },
                },
                {
                  label: "System Drive",
                  percent: systemDrive.percent,
                  details: systemDrive,
                },
                {
                  label: "Recordings Drive (/mnt/recordings)",
                  percent: recordingsDrive?.percent,
                  details: recordingsDrive,
                },
              ].map(({ label, percent, details }, index) => (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography fontWeight="bold" paddingBottom="10px">
                    {label}
                  </Typography>
                  <Box
                    width="150px"
                    justifyContent="center"
                    alignItems="center"
                    textAlign={"center"}
                  >
                    <Doughnut
                      data={createDoughnutData(percent)}
                      options={{
                        responsive: true,
                        cutout: "70%",
                        plugins: {
                          tooltip: { enabled: false },
                          legend: { display: false },
                        },
                      }}
                    />
                    <Typography
                      color={getPercentageColor(percent)}
                      variant="h5"
                      pt={1}
                    >
                      {percent}%
                    </Typography>
                    {details && (
                      <Box
                        display="flex"
                        gap="5px"
                        textAlign="center"
                        flexDirection={"column"}
                      >
                        <Typography>
                          Total: {(details.total / 1024 ** 3).toFixed(2)} GB
                        </Typography>
                        <Typography>
                          Used: {(details.used / 1024 ** 3).toFixed(2)} GB
                        </Typography>
                        <Typography>
                          Free: {(details.free / 1024 ** 3).toFixed(2)} GB
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Processes Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" alignItems="center">
            <RadioButtonCheckedIcon
              sx={{
                marginRight: 1,
                color: hasRunningProcesses ? "error.main" : "inherit",
              }}
            />
            <Typography variant="h6">Recordings</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {configs[0].processes?.map((process, index) => (
              <Card key={index} sx={{ p: 1 }}>
                <CardContent>
                  <Typography
                    variant="subtitle2"
                    fontWeight="bold"
                    sx={{ mb: 1 }}
                    align="center"
                  >
                    Rec {index + 1}: {process.streamid}
                  </Typography>
                  <Box display="flex" flexDirection="column" gap={0.5}>
                    <Box display="flex" gap="10px">
                      <Typography variant="body2">
                        <strong>PID:</strong>
                      </Typography>
                      <Typography variant="body2">{process.pid}</Typography>
                    </Box>
                    <Box display="flex" gap="10px">
                      <Typography variant="body2">
                        <strong>Runtime:</strong>
                      </Typography>
                      <Typography variant="body2">{process.runtime}</Typography>
                    </Box>
                    <Box display="flex" gap="10px">
                      <Typography variant="body2">
                        <strong>Start:</strong>
                      </Typography>
                      <Typography variant="body2">
                        {process.start_time}
                      </Typography>
                    </Box>
                    <Box display="flex" gap="10px">
                      <Typography variant="body2">
                        <strong>Stream ID:</strong>
                      </Typography>
                      <Typography variant="body2">
                        {process.streamid}
                      </Typography>
                    </Box>
                    <Box display="flex" gap="10px">
                      <Typography variant="body2">
                        <strong>Output Size:</strong>
                      </Typography>
                      <Typography variant="body2">
                        {process.output_file_size}
                      </Typography>
                    </Box>
                    <Box display="flex" gap="10px">
                      <Typography variant="body2">
                        <strong>Output File:</strong>
                      </Typography>
                      <Typography variant="body2" noWrap>
                        {extractFilename(process.cmdline)}
                      </Typography>
                    </Box>
                    {process.json_metadata && (
                      <Box
                        display="flex"
                        mt={1}
                        mb={1}
                        justifyContent="left"
                        alignItems={"center"}
                        gap="5px"
                      >
                        <Typography variant="body2">
                          <strong>Nablet JSON:</strong>
                        </Typography>
                        <JSONDialog
                          input_json={process.json_metadata}
                          name={`Metadata ${index + 1}`}
                        />
                      </Box>
                    )}
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => openCommand(process.cmdline.join(" "))}
                    >
                      Show Command
                    </Button>
                    <Box
                      display="flex"
                      mt={3}
                      gap="20px"
                      justifyContent={"left"}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => requestRestartRecording(process)}
                      >
                        <Box
                          display="flex"
                          alignItems={"center"}
                          gap="5px"
                          justifyContent={"center"}
                        >
                          <RestartAltIcon></RestartAltIcon>
                          <Typography> Restart</Typography>
                        </Box>
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => requestStopRecording(process)}
                      >
                        <Box
                          display="flex"
                          alignItems={"center"}
                          gap="5px"
                          justifyContent={"center"}
                        >
                          <StopCircleIcon></StopCircleIcon>
                          <Typography> Stop</Typography>
                        </Box>
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>

          {/* Command Dialog */}
          <Dialog open={openCommandDialog} onClose={closeCommandDialog}>
            <DialogTitle>Command Details</DialogTitle>
            <DialogContent>
              <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
                {commandDialogContent}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeCommandDialog} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </AccordionDetails>
      </Accordion>

      {/* Failed Processes Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" alignItems="center">
            <WarningIcon
              sx={{
                marginRight: 1,
                color: hasFailedProcesses ? "error.main" : "action",
              }}
            />
            <Typography variant="h6">Failed Processes</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            justifyContent={"left"}
            flexWrap={"wrap"}
            gap="20px"
          >
            {hasFailedProcesses ? (
              Object.entries(configs[0].failedProcesses.failed_processes).map(
                ([streamid, failedProcesses]) =>
                  failedProcesses.map((failedProcess, index) => (
                    <Box
                      key={`${streamid}-${index}`}
                      mb={2}
                      borderBottom="1px solid #ccc"
                      pb={2}
                    >
                      <Typography variant="subtitle1" fontWeight="bold">
                        Failed Process {index + 1}
                      </Typography>
                      <Typography>
                        Stream ID: {failedProcess.streamid}
                      </Typography>
                      <Typography>
                        Stopped At: {failedProcess.stopped_at}
                      </Typography>
                    </Box>
                  ))
              )
            ) : (
              <Typography>No failed processes.</Typography>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
