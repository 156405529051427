import React from "react";
import { Grid, Typography, Link, Paper } from "@mui/material";

const OvenDetails = ({ oven }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        Distributions:{" "}
        {Array.isArray(oven["distributions"])
          ? oven["distributions"].join(", ")
          : ""}{" "}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>
          Host:{" "}
          <Link href={`https://${oven["host"]}`} underline="hover">
            {oven["host"]}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Region: {oven["region"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>AWS Instance ID: {oven["aws_instance_id"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>IP: {oven["ip"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Internal IP: {oven["internal_ip"]}</Typography>
      </Grid>
    </Grid>
  );
};

export default OvenDetails;
