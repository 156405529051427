import React, { useContext } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MyContext } from "../../context/Context";
import TableAccordion from "./TableAccordion";
import schemas from "./schemas.json"; // Adjust path as necessary

export default function TableAccordions() {
  const { state } = useContext(MyContext);
  // Group tables by scope
  const tablesByScope = schemas.scopes.reduce((acc, scope) => {
    acc[scope] = Object.keys(state.database).filter(
      (key) => schemas.tables[key]?.scope === scope
    );
    return acc;
  }, {});

  return (
    <div>
      {schemas.scopes.map((scope, scopeIndex) => (
        <Accordion key={scope} defaultExpanded={scope == "general"}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${scope}-content`}
            id={`panel-${scope}-header`}
          >
            <strong>{scope.charAt(0).toUpperCase() + scope.slice(1)}</strong>
          </AccordionSummary>
          <AccordionDetails>
            {tablesByScope[scope].map((key, index) => (
              <div key={key}>
                <TableAccordion tableName={key} index={index} />
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
