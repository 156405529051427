import React from "react";
import AddDistributionForm from "./AddDistributionForm"; // Import your specific form components
import AddEventForm from "./AddEventForm";
import AddWorkflowProfileForm from "./AddWorkflowProfileForm";
import { useApiRequest } from "../../Utility/useAPIRequest";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import AddGatewayForm from "./AddGatewayForm";
import AddStreamhubForm from "./AddStreamhubForm";
import AddTakerProfileForm from "./AddTakerProfileForm";
import AddSingularProfileForm from "./AddSingularProfileForm";
import AddTvuProfileForm from "./AddTvuProfileForm";
import AddGatewayProfileForm from "./AddGatewayProfileForm";
import AddGatewayAbilityForm from "./AddGatewayAbilityForm";
import AddStreamhubAbilityForm from "./AddStreamhubAbilityForm";
import AddHBMCArchiveAbilities from "./AddHBMCArchiveAbilitiesForm";
import AddTVUAbilitiesForm from "./AddTVUAbilitiesForm";
import AddExchangeProfilesForm from "./AddExchangeProfilesForm";
import AddRecordProfilesForm from "./AddRecordProfilesForm";
import AddMixProfilesForm from "./AddMixProfilesForm";
import AddGraphicsProfilesForm from "./AddGraphicsProfilesForm";
import AddStreamhubProfileForm from "./AddStreamhubProfileForm";
import AddHBMCArchiveProfileForm from "./AddHBMCArchiveProfilesForm";
import AddTournamentsForm from "./AddTournamentsForm";
import AddElixirProfileForm from "./AddElixirProfileForm";
import AddElixirAbilitiesForm from "./AddElixirAbilitiesForm";
import AddContributionSimulatorProfile from "./AddContributionSimulatorProfile";
import AddContributionSimulatorAbilityForm from "./AddContributionSimulatorAbilityForm";
import AddSpalkAbilitiesForm from "./AddSpalkAbilitiesForm";
import AddSpalkProfileForm from "./AddSpalkProfileForm";
import AddCloudCutterForm from "./AddCloudCutterForm";
import AddNabletsForm from "./AddNabletsForm";
import AddNabletProfileForm from "./AddNabletProfileForm";
import AddCloudcuttersProfileForm from "./AddCloudcuttersProfileForm";
import AddObserverForm from "./AddObserverForm";
import AddPromexForm from "./AddPromexForm";
import AddPromexsProfileForm from "./AddPromexsProfileForm";
import AddObserversProfileForm from "./AddObserversProfileForm";
import JsonImporter from "../../Utility/JsonImporter";
import AddTVUMediaHubProfileForm from "./AddTvuMediaHubProfileForm";
import AddOvensProfileForm from "./AddOvensProfileForm";

const AddItemFormRouting = ({ open, onClose, schema, tableName }) => {
  const { makeApiRequest } = useApiRequest();

  const addItemToTable = async (newItemData) => {
    const endpoint = process.env.REACT_APP_DATABASE_URL;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        command: "command_any_table",
        obj: { command: "create", item: newItemData, table_name: tableName },
      }),
    };
    const { success, data, error } = await makeApiRequest(
      endpoint,
      options,
      tableName
    );
    if (success) {
      console.log(data);
    } else {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleAddNewItem = (newItemData, dbCommand1, dbCommand2) => {
    addItemToTable(newItemData, dbCommand1, dbCommand2);
  };

  switch (tableName) {
    // case "distributions":
    //   return (
    //     <AddDistributionForm
    //       open={open}
    //       onClose={onClose}
    //       onAdd={handleAddNewItem}
    //       schema={schema}
    //       tableName={tableName}
    //     />
    //   );
    case "events":
      return (
        <AddEventForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "workflow_profiles":
      return (
        <AddWorkflowProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "gateways":
      return (
        <AddGatewayForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "streamhubs":
      return (
        <AddStreamhubForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "taker_profiles":
      return (
        <AddTakerProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "singular_profiles":
      return (
        <AddSingularProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "tvu_profiles":
      return (
        <AddTvuProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "gateway_profiles":
      return (
        <AddGatewayProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "gateways_abilities":
      return (
        <AddGatewayAbilityForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "streamhub_abilities":
      return (
        <AddStreamhubAbilityForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "hbmc_archive_abilities":
      return (
        <AddHBMCArchiveAbilities
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "tvu_abilities":
      return (
        <AddTVUAbilitiesForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "exchange_profiles":
      return (
        <AddExchangeProfilesForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "record_profiles":
      return (
        <AddRecordProfilesForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "mix_profiles":
      return (
        <AddMixProfilesForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "graphics_profiles":
      return (
        <AddGraphicsProfilesForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "streamhub_profiles":
      return (
        <AddStreamhubProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "hbmc_archive_profiles":
      return (
        <AddHBMCArchiveProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "tournaments":
      return (
        <AddTournamentsForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "elixir_profiles":
      return (
        <AddElixirProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "elixir_abilities":
      return (
        <AddElixirAbilitiesForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "cloudcutters":
      return (
        <AddCloudCutterForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "observers":
      return (
        <AddObserverForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "promexs":
      return (
        <AddPromexForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "spalk_abilities":
      return (
        <AddSpalkAbilitiesForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "spalk_profiles":
      return (
        <AddSpalkProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "contribution_simulator_profiles":
      return (
        <AddContributionSimulatorProfile
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "contribution_simulator_abilities":
      return (
        <AddContributionSimulatorAbilityForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "nablets":
      return (
        <AddNabletsForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "nablet_profiles":
      return (
        <AddNabletProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "ovens_profiles":
      return (
        <AddOvensProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "cloudcutters_profiles":
      return (
        <AddCloudcuttersProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "observers_profiles":
      return (
        <AddObserversProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "promexs_profiles":
      return (
        <AddPromexsProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    case "tvu_mediahub_profiles":
      return (
        <AddTVUMediaHubProfileForm
          open={open}
          onClose={onClose}
          onAdd={handleAddNewItem}
          schema={schema}
          tableName={tableName}
        />
      );
    default:
      return (
        <>
          <Dialog open={open} onClose={onClose}>
            <DialogTitle>Disabled</DialogTitle>
          </Dialog>
        </>
      );
  }
};

export default AddItemFormRouting;
