import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  IconButton,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import { MyContext } from "../../context/Context";
import PlayerCollection from "./PlayerCollection";
import Nirvana from "../Utility/Nirvana";
import TvIcon from "@mui/icons-material/Tv";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
export default function MultiviewerFeeds() {
  const { state } = React.useContext(MyContext);

  const [selectedEventId, setSelectedEventId] = useState("");
  const [selectedEventUids, setSelectedEventUids] = useState(
    () => JSON.parse(localStorage.getItem("selectedEventUids")) || []
  );
  const [showBackup, setShowBackup] = useState(false);
  const [showControls, setShowControls] = useState(false);

  useEffect(() => {
    localStorage.setItem(
      "selectedEventUids",
      JSON.stringify(selectedEventUids)
    );
  }, [selectedEventUids]);

  const currentTime = Date.now();
  const marginMinutes = 900 * 60 * 1000;

  const events = state?.database?.events || [];
  const workflowProfiles = state?.database?.workflow_profiles || [];
  const distributions = state?.database?.distributions || [];
  const ovens = state?.database?.ovens || [];

  const validEvents = events.filter((event) => {
    const startTime = parseInt(event.start_time, 10);
    const endTime = parseInt(event.end_time, 10);
    return (
      currentTime >= startTime - marginMinutes &&
      currentTime <= endTime + marginMinutes
    );
  });

  const availableEvents = validEvents.filter(
    (event) => !selectedEventUids.includes(event.uid)
  );

  const handleEventChange = (event) => {
    const eventId = event.target.value;
    if (!selectedEventUids.includes(eventId)) {
      setSelectedEventUids([...selectedEventUids, eventId]);
    }
    setSelectedEventId("");
  };

  const handleRemoveEvent = (eventId) => {
    setSelectedEventUids(selectedEventUids.filter((uid) => uid !== eventId));
  };

  const handleClearAll = () => {
    setSelectedEventUids([]);
  };

  const handleReorderEvent = (eventId, direction) => {
    const index = selectedEventUids.findIndex((uid) => uid === eventId);
    if (index === -1) return;

    const newUids = [...selectedEventUids];
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    if (targetIndex >= 0 && targetIndex < newUids.length) {
      [newUids[index], newUids[targetIndex]] = [
        newUids[targetIndex],
        newUids[index],
      ];
      setSelectedEventUids(newUids);
    }
  };

  if (!state?.database) {
    return <Nirvana />;
  }

  return (
    <div style={{ width: "100%", marginLeft: "10px", marginRight: "10px" }}>
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="multiviewer-content"
          id="multiviewer-header"
        >
          <Box display="flex" alignItems="center" gap="5px">
            <TvIcon />
            <Typography variant="h6">Multiviewer Feeds</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "stretch",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                flexWrap: "wrap",
                justifyContent: "space-between",
                minHeight: "60px",
              }}
            >
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel>Select Event</InputLabel>
                <Select
                  value={selectedEventId}
                  onChange={handleEventChange}
                  label="Select Event"
                >
                  {availableEvents.map((event) => (
                    <MenuItem key={event.uid} value={event.uid}>
                      {event.alias}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box display="flex" gap={2} alignItems="center">
                {/* Show Backup Toggle */}
                <FormControlLabel
                  control={
                    <Switch
                      checked={showBackup}
                      onChange={() => setShowBackup((prev) => !prev)}
                      color="secondary"
                    />
                  }
                  label="Backup Streams"
                />{" "}
                <FormControlLabel
                  control={
                    <Switch
                      checked={showControls}
                      onChange={() => setShowControls((prev) => !prev)}
                      color="secondary"
                    />
                  }
                  label="Show Controls"
                />
                {/* Clear All Button */}
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<ClearIcon />}
                  onClick={handleClearAll}
                  disabled={selectedEventUids.length === 0}
                  sx={{ height: "40px" }}
                >
                  Clear All
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                minHeight: "60px",
              }}
            >
              {selectedEventUids.map((uid, index) => {
                const event = events.find((ev) => ev.uid === uid);
                return (
                  <Chip
                    key={uid}
                    label={`${event?.alias || "Unknown"}  (${
                      distributions.find(
                        (item) => item.uid === event?.distribution_uid
                      )?.distribution || "N/A"
                    })`}
                    sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    onDelete={() => handleRemoveEvent(uid)}
                    deleteIcon={<DeleteIcon />}
                    icon={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleReorderEvent(uid, "up")}
                          disabled={index === 0}
                        >
                          <ArrowDropUpIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleReorderEvent(uid, "down")}
                          disabled={index === selectedEventUids.length - 1}
                        >
                          <ArrowDropDownIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    }
                  />
                );
              })}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      <PlayerCollection
        selectedEventUids={selectedEventUids}
        events={events}
        workflowProfiles={workflowProfiles}
        distributions={distributions}
        ovenHosts={ovens}
        showBackup={showBackup} // Pass the "Show Backup" option
        showControls={showControls}
      />
    </div>
  );
}
