import React from "react";
import GatewayRoute from "./GatewayRoute";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Paper } from "@mui/material";

export default function GatewayConfig({ configs, gateway, triggerReload }) {
  return (
    <div>
      <Paper>
        {configs?.map((conf, index) =>
          conf.config.map((route, index) => (
            <Accordion
              key={index}
              style={
                index % 2 !== 0
                  ? {
                      backgroundColor: "#f2f3f5",
                    }
                  : {}
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                {/* You can choose to display a summary here, such as the route name */}
                <Typography>Route: {route.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <GatewayRoute
                  route={route}
                  host={conf.host}
                  index={index}
                  gateway={gateway}
                  triggerReload={triggerReload}
                />
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Paper>
    </div>
  );
}
