import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import OvenVideoPlayer from "./OvenVideoPlayer";

export default function VideoElement({
  host,
  port,
  streamName,
  playerId,
  label,
  event,
  showControls,
}) {
  const [hasError, setHasError] = useState(false); // Track error state
  const [isStreaming, setIsStreaming] = useState(false); // Track streaming state

  const handlePlayerError = () => {
    setHasError(true);
    setIsStreaming(false); // Reset streaming state on error
  };

  const handleStreamingStart = () => {
    setHasError(false); // Reset error state
    setIsStreaming(true); // Set streaming state
  };

  return (
    <Box
      sx={{
        minWidth: "19.9%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "2px",
        overflow: "hidden",
        backgroundColor: "black",
      }}
    >
      <Box
        sx={{
          width: "100%",
          paddingBottom: "56.25%", // 16:9 aspect ratio
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <OvenVideoPlayer
            host={host}
            port={port}
            streamName={streamName}
            autoStart={true}
            autoFallback={true}
            mute={true}
            playerId={playerId}
            onError={handlePlayerError} // Pass the error handler
            onPlay={handleStreamingStart} // Handle streaming start
            showControls={showControls}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography
          variant="body2"
          sx={{
            textAlign: "left",
            margin: "8px",
            color: "white",
          }}
        >
          {event.alias}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              margin: "8px",
              color: hasError ? "#ff6026" : "white", // Turn red if there's an error
              display: "flex",
              alignItems: "center",
            }}
          >
            {label}
          </Typography>
          {hasError ? (
            <ErrorIcon
              sx={{
                color: "#ff6026",
                animation: "pulse 1.5s infinite", // Pulse effect
                "@keyframes pulse": {
                  "0%": { transform: "scale(1)", opacity: 1 },
                  "50%": { transform: "scale(1.3)", opacity: 0.8 },
                  "100%": { transform: "scale(1)", opacity: 1 },
                },
              }}
            />
          ) : isStreaming ? (
            <CastConnectedIcon
              sx={{
                color: "#2ed133",
              }}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
