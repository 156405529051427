import React, { useEffect } from "react";
import OvenPlayer from "ovenplayer";

const OvenVideoPlayer = ({
  host,
  port,
  streamName,
  autoStart = true,
  autoFallback = true,
  mute = false,
  playerId,
  onError,
  onPlay,
  showControls,
}) => {
  useEffect(() => {
    if (!host || !port || !streamName) {
      console.warn(
        "Missing required streaming configuration (host, port, or streamName)"
      );
      return;
    }

    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/npm/hls.js@latest/dist/hls.min.js";
    script.async = true;
    document.body.appendChild(script);

    let player;

    const observer = new MutationObserver(() => {
      const contextPanel = document.querySelector(".op-context-panel");
      if (contextPanel) {
        contextPanel.style.display = "none"; // Hide the panel
      }
    });

    const initializePlayer = () => {
      try {
        // Start observing the body for changes
        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });

        player = OvenPlayer.create(playerId, {
          autoStart: autoStart,
          autoFallback: autoFallback,
          mute: mute,
          hlsConfig: {
            liveSyncDuration: 2,
            liveMaxLatencyDuration: 5,
            maxLiveSyncPlaybackRate: 1,
          },
          sources: [
            {
              type: "webrtc",
              file: `wss://${host}:${port}${streamName}/abr?username=someUser&token=123456789`,
            },
            {
              type: "ll-hls",
              file: `https://${host}:${port}${streamName}/llhls.m3u8?username=someUser&token=123456789`,
            },
          ],
        });
        player.showControls(showControls);

        player.on("ready", () => {
          console.log("Player is ready.");
        });

        player.on("play", () => {
          console.log("Streaming started.");
          if (onPlay) onPlay(); // Notify parent that streaming started
        });

        player.on("error", (error) => {
          console.error("OvenPlayer encountered an error:", error);
          if (onError) onError(); // Notify parent of the error
        });
        // Set the accent color dynamically
        const playerElement = document.getElementById(playerId);
        if (playerElement) {
          playerElement.style.setProperty("--op-accent-color", "#1976d2");
        }
        return player;
      } catch (error) {
        console.error("Error initializing OvenPlayer:", error);
        if (onError) onError(); // Notify parent of initialization error
      }
    };

    // Initialize the player
    player = initializePlayer();

    return () => {
      observer.disconnect(); // Stop observing on component unmount
      if (player) player.remove(); // Remove the player
      document.body.removeChild(script); // Remove the script
    };
  }, [
    host,
    port,
    streamName,
    autoStart,
    autoFallback,
    mute,
    playerId,
    onError,
    onPlay,
    showControls,
  ]);

  return <div id={playerId}></div>;
};

export default OvenVideoPlayer;
