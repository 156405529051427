import React, { useState, useContext, useEffect } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ScheduleUpdater from "./ScheduleUpdater";
import useConfirm from "../Utility/useConfirm";
import useCronTranslator from "../Utility/useCronTranslator";
import { useApiRequest } from "../Utility/useAPIRequest";
import moment from "moment-timezone";
import { MyContext } from "../../context/Context";
import JSONDialog from "../Utility/JSONDialog";
import { useTheme, styled } from "@mui/material/styles";

const ScheduleRow = ({ schedule, timezone, rowIndex }) => {
  const { onSuccessfulModification } = useContext(MyContext);
  const { requestConfirm, ConfirmDialog } = useConfirm();
  const [open, setOpen] = useState(false);
  const translateCronExpression = useCronTranslator(timezone);
  const { makeApiRequest } = useApiRequest();
  const theme = useTheme();
  const isOdd = rowIndex % 2 !== 0;
  const [timeLeft, setTimeLeft] = useState(null);
  const [isOverdue, setIsOverdue] = useState(false);

  // Pulsating animation for AccessTimeIcon when timeLeft is <= 5
  const PulsatingIcon = styled(AccessTimeIcon)(({ theme }) => ({
    animation: "pulse 1.5s ease-in-out infinite",
    "@keyframes pulse": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.4)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
  }));

  const cronToUnixMs = (cronExpression) => {
    const cronParts = cronExpression
      .replace("cron(", "")
      .replace(")", "")
      .split(" ");
    const [minutes, hours, day, month, , year] = cronParts;
    const date = moment.utc({
      year: Number(year),
      month: Number(month) - 1,
      day: Number(day),
      hour: Number(hours),
      minute: Number(minutes),
    });
    return date.isValid() ? date.valueOf() : NaN;
  };

  const scheduleTimeMs = cronToUnixMs(schedule.ScheduleExpression);

  useEffect(() => {
    const updateRemainingTime = () => {
      const currentTime = moment.utc().valueOf();
      const timeDifferenceMinutes = (scheduleTimeMs - currentTime) / 60000;
      setTimeLeft(Math.abs(Math.ceil(timeDifferenceMinutes)));
      setIsOverdue(timeDifferenceMinutes < 0);
    };

    updateRemainingTime(); // Initial calculation
    const intervalId = setInterval(updateRemainingTime, 15000); // Update every 15 seconds

    return () => clearInterval(intervalId);
  }, [scheduleTimeMs]);

  const handleDelete = async () => {
    try {
      await requestConfirm("Are you sure you want to delete this schedule?");
      const url = process.env.REACT_APP_DELETE_SCHEDULE_URL;
      const result = await makeApiRequest(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          schedules: [{ schedule_name: schedule.Name, group_name: "default" }],
        }),
      });
      if (result.success) {
        setOpen(false);
      }
    } catch (error) {
      console.log("Delete action cancelled by user");
    }
  };

  const convertToCronExpression = (dateTime, timezone) => {
    const utcDateTime = moment.tz(dateTime, timezone).utc();
    return `cron(${utcDateTime.format("m")} ${utcDateTime.format(
      "H"
    )} ${utcDateTime.format("D")} ${utcDateTime.format(
      "M"
    )} ? ${utcDateTime.format("YYYY")})`;
  };

  const updateTime = async (time, tz) => {
    try {
      await requestConfirm("Are you sure you want to update this schedule?");
      const url = process.env.REACT_APP_UPDATE_SCHEDULE_URL;
      const cron_expression = convertToCronExpression(time, tz);
      const result = await makeApiRequest(url, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          schedule_updates: [
            {
              schedule_name: schedule.Name,
              cron_expression,
              description: schedule.Description,
              group_name: schedule.GroupName,
            },
          ],
        }),
      });
      if (result.success) setOpen(false);
    } catch (error) {
      console.log("Update action cancelled by user");
    }
  };

  return (
    <React.Fragment>
      {ConfirmDialog}
      <TableRow
        style={{
          backgroundColor: isOdd
            ? theme.palette.mode === "dark"
              ? "#2e2e2e"
              : ""
            : "#f1f1f1",
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon color="primary" />
            ) : (
              <KeyboardArrowDownIcon color="primary" />
            )}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {(() => {
            const [event, number, task, type, index] =
              schedule.Description.split(";");
            return (
              <span>
                Event: <strong>{event}</strong>, Match#:{" "}
                <strong>{number}</strong>, Task:{" "}
                <strong>{task.replace(/_/g, " ")}</strong>, Type:{" "}
                <strong>{type.charAt(0).toUpperCase() + type.slice(1)}</strong>,
                Index: <strong>{index}</strong>
              </span>
            );
          })()}
        </TableCell>

        {/* Remaining Time Icon Column */}
        <TableCell align="left">
          {timeLeft !== null && (
            <Box display="flex" alignItems={"center"} gap="10px">
              {timeLeft <= 5 ? (
                <PulsatingIcon
                  style={{ color: theme.palette.secondary.main }}
                />
              ) : (
                <AccessTimeIcon style={{ color: theme.palette.warning.main }} />
              )}
              {timeLeft < 270 ? (
                <p>{`${timeLeft} minutes ${
                  isOverdue ? "ago" : "remaining"
                }`}</p>
              ) : (
                <p>{`Scheduled`}</p>
              )}
            </Box>
          )}
        </TableCell>

        <TableCell>
          {translateCronExpression(schedule.ScheduleExpression)}
        </TableCell>
        <TableCell component="th" scope="row">
          <JSONDialog input_json={schedule.Target.Input} name={schedule.Name} />
        </TableCell>
        <TableCell align="right">
          <Tooltip title="Delete Schedule">
            <IconButton aria-label="delete" onClick={handleDelete}>
              <DeleteIcon color="secondary" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <ScheduleUpdater onUpdateTime={updateTime} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ScheduleRow;
