import React from "react";
import { Box, Typography } from "@mui/material";
import MultiviewerFeeds from "./MultiviewerFeeds";

export default function Monitor() {
  return (
    <Box display="flex" justifyContent={"center"}>
      <MultiviewerFeeds></MultiviewerFeeds>
    </Box>
  );
}
