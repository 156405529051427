import React from "react";
import { Grid, Typography, Link, Paper } from "@mui/material";

const NabletDetails = ({ nablet }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        Distributions:{" "}
        {Array.isArray(nablet["distributions"])
          ? nablet["distributions"].join(", ")
          : ""}{" "}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>
          Host:{" "}
          <Link href={`https://${nablet["host"]}`} underline="hover">
            {nablet["host"]}
          </Link>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Typography>Region: {nablet["region"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>AWS Instance ID: {nablet["aws_instance_id"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>IP: {nablet["ip"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Internal IP: {nablet["internal_ip"]}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography>Path: {nablet["path"]}</Typography>
      </Grid>
    </Grid>
  );
};

export default NabletDetails;
